<template>
  <div v-if="table_permissions" class="custom-content-height">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      fixed-header
      light
      dense
      :height="getvh(56)"
      show-select
      :single-select="disableSelectAllCheckbox"
      :items-per-page="itemPerPage"
      item-key="id"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      @dblclick:row="showDetail"
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <!--    no-data end-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-1"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          />
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail :table_properties="table_properties" />
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>

          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <!--begin::Button-->
            <!-- <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0 mt-1"
              v-if="permissionChecker('create')"
            >
              <b-dropdown
                toggle-class="btn btn--export-filter px-2"
                ref="action-dropdown"
                id="action-dropdown-create"
                class="mr-3 mb-1 p-0 col-12"
                menu-class="m-0 p-0 ml-n4"
                no-caret
                variant="upload"
              >
                <template v-slot:button-content>
                  <span class="ml-3">Create Return</span>
                  <span class="svg-icon ml-1 mr-0">
                    <v-icon size="18">mdi-menu-down</v-icon>
                  </span>
                </template>
                <div class="q-status-menu py-1 w-175px">
                  <div
                    class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
                    @click="() => toggleCreateReturnModal('iq')"
                  >
                    <div
                      class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium my-2"
                    >
                      Internal
                    </div>
                  </div>
                  <div
                    class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
                    @click="() => toggleCreateReturnModal('external')"
                  >
                    <div
                      class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium my-2"
                    >
                      External
                    </div>
                  </div>
                </div>
              </b-dropdown>
            </div> -->
            <!-- <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('view')"
            >
              <button
                @click.prevent="assignCarrier"
                class="btn btn--export-filter ml-2"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-cart-plus</v-icon>
                </span>
                Assign Carrier
              </button>

              <ActionDialog
                :refresher="updateTableContent"
                :pageLoader="pageLoad"
                ref="actionDialog"
              />
            </div> -->
            <!-- <div class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0 mt-1">
              <Print :items="selected" :types="types" class="w-100" />
            </div> -->
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('export')"
            >
              <button
                @click="$refs.exportSidebar.$data.exportSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                Export
              </button>
            </div>
            <!--end::Button-->
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="$refs.filterSidebar.$data.filterSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
            >
              <button @click="showHoldOnModal" class="btn btn--print-upload">
                <span class="svg-icon">
                  <v-icon size="18">mdi-package-variant-closed</v-icon>
                </span>
                On Hold
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
            >
              <button @click="showCancelModal" class="btn btn--print-upload">
                <span class="svg-icon">
                  <v-icon dark size="18">mdi-alert-octagon-outline</v-icon>
                </span>
                Cancel Order
              </button>
            </div>
            <!-- <div
              v-if="showBulkActions"
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0 mt-1"
            >
              <b-dropdown
                ref="action-dropdown"
                id="action-dropdown"
                text="Actions"
                class="mr-3 mb-1 p-0 col-12"
                toggle-class="poppins btn btn--export-filter"
                menu-class="m-0 p-0 ml-n4"
                no-caret
                variant="upload"
              >
                <template v-slot:button-content>
                  <div class="px-2">
                    <span class="svg-icon">
                      <v-icon size="18">mdi-dns</v-icon>
                    </span>
                    <span>Actions</span>
                    <span class="svg-icon mr-0 ml-2">
                      <v-icon size="18">mdi-chevron-down</v-icon>
                    </span>
                  </div>
                </template>
                <div class="q-status-menu py-1 w-175px">
                  <div
                    class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
                    @click="bulkEdit"
                    v-if="permissionChecker('bulk_edit_destination_address')"
                  >
                    <div
                      class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium my-2"
                    >
                      Edit Destination
                    </div>
                  </div>
                  <div
                    class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
                    @click="bulkAssignAwb"
                    v-if="permissionChecker('assign_awb')"
                  >
                    <div
                      class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium my-2"
                    >
                      Assign AWB
                    </div>
                  </div>
                  <div
                    class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
                    @click="toggleCreateAsnModal"
                    v-if="permissionChecker('return_order_asn_create')"
                  >
                    <div
                      class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium my-2"
                    >
                      Create Return ASN
                    </div>
                  </div>
                  <div
                    class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
                    @click="createReturnInWMS"
                    v-if="permissionChecker('create_return_in_wms')"
                  >
                    <div
                      class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium my-2"
                    >
                      Create Return In WMS
                    </div>
                  </div>
                  <div
                    class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
                    @click="resetToAssigned"
                    v-if="permissionChecker('order_reset_to_assigned')"
                  >
                    <div
                      class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium my-2"
                    >
                      Reset to Assigned
                    </div>
                  </div>
                  <div
                    class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
                    @click="action3plStatusSync"
                    v-if="permissionChecker('return_3pl_status_sync')"
                  >
                    <div
                      class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium my-2"
                    >
                      3pl Status Sync
                    </div>
                  </div>
                </div>
              </b-dropdown>
            </div> -->

            <!-- <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              v-if="permissionChecker('update_return_status')"
            >
              <button
                class="btn btn--export-filter w-100"
                @click.prevent="() => updateStatus(null, true)"
              >
                <span class="svg-icon d-none d-sm-inline-block">
                  <v-icon size="18">mdi-refresh</v-icon>
                </span>
                Update Status
              </button>
              <ChangeStatus
                :refresher="updateTableContent"
                :pageLoader="pageLoad"
                ref="changeStatus"
                :ids="updateStatusItemId"
                :isMultiple="getIsUpdateStatusItemsMultiple"
              />
            </div> -->
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->
        <!-- Filter Sidebar start -->
        <FilterSidebar
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->
        <Others ref="otherData" />
      </template>
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>
      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        />
      </template>
      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissionChecker('update')">
              <v-btn class="w-100 text-left" @click="() => editItem(item)" text
                >Edit</v-btn
              >
            </div>
            <div v-if="permissionChecker('view')">
              <v-btn
                class="w-100 text-left"
                @click="loadDetailData(item.id)"
                text
                >View</v-btn
              >
            </div>

            <div v-if="permissionChecker('destroy')">
              <v-btn
                class="w-100 text-left text-danger"
                @click="() => handleDelete(item)"
                text
                >Delete</v-btn
              >
            </div>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    />

    <QueueUploadModal
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="uploadDoc"
    />
    <ActionDialog
      v-if="permissionChecker('view')"
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      :showNextOrder="showNextOrder"
      :showPrevOrder="showPrevOrder"
      :extraData="extraData"
      ref="actionDialog"
    />
    <v-dialog v-model="dialog2" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>Select Hold On Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="holdOn_reason" column>
            <v-radio
              v-for="item in extraData.hold_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>

          <v-menu
            v-if="holdOn_reason == 1030"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="holdOn_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="holdOn_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionOnHold">
            On Hold
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>Cancel Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="cancel_reason" column>
            <v-radio
              v-for="item in extraData.cancel_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog3 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionCancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
  // SET_CREATE_RETURN_TYPE,
  // SET_SERVER_DATA,
  SET_ACTION_TYPE,
} from "@/core/services/store/pendingReturn.module";
import {
  SET_PAGE_LOADING,
  // SET_REPORT_MODAL_DATA,
  // SET_REPORT_MODAL_STATE,
} from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar";

import ActionDialog from "@/own/components/orderManagement/returned/ActionDialog.vue";
import Others from "@/own/components/orderManagement/returned/Others.vue";
// import ChangeStatus from "@/own/components/orderManagement/returned/ChangeStatus.vue";

import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";
import Pagination from "@/own/components/pagination/Pagination.vue";
import ApiService from "@/core/services/api.service";
import QueueUploadModal from "@/own/components/Modals/QueueUploadModal";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";
// import AssignCarrier from "@/own/components/orderManagement/returned/AssignCarrier.vue";
// import BulkEditDestination from "@/own/components/orderManagement/returned/BulkEditDestination.vue";
// import BulkAssignAwb from "@/own/components/orderManagement/returned/BulkAssignAwb.vue";
// import CreateReturnAsnModal from "@/own/components/orderManagement/returned/CreateReturnAsnModal.vue";
// import Print from "@/own/components/orderManagement/returned/Print.vue";

export default {
  name: "DataTableNew",
  mixins: [getPageWidthHeight],
  components: {
    QueueUploadModal,
    DatatableDetail,
    Pagination,
    FilterSidebar,
    ComponentSelector,
    ExportSidebar,
    // AssignCarrier,
    ActionDialog,
    Others,
    // ChangeStatus,
    // BulkEditDestination,
    // BulkAssignAwb,
    // CreateReturnAsnModal,
    // Print,
  },
  props: {
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
    offset: true,

    updateStatusItemId: null,
    isUpdateStatusItemsMultiple: true,
    serverData: null,

    dialog2: false,
    dialog3: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    menu2: false,
  }),
  async beforeMount() {
    await this.$store.dispatch(UPDATE_TABLE_STRUCTURE);
    await this.$store.dispatch(UPDATE_TABLE_DATA);
    this.$store.commit(SET_PAGE_LOADING, false);
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    extraData() {
      return this.$store.getters.getPENDINGRETURNSExtraData;
    },
    types: function() {
      return this.$store.getters.getPENDINGRETURNSPrintTypes;
    },
    // getIsUpdateStatusItemsMultiple() {
    //   return this.isUpdateStatusItemsMultiple;
    // },
    isToolbarVisiable: function() {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    //pagination
    getItemPerPage: function() {
      return this.$store.getters.getPENDINGRETURNSTableState.per_page;
    },
    getPageNumber: function() {
      return this.$store.getters.getPENDINGRETURNSTableState.page;
    },
    totalItems: function() {
      return this.$store.getters.getPENDINGRETURNSTableData.total;
    },
    pageCount: function() {
      return this.$store.getters.getPENDINGRETURNSTableData.last_page;
    },
    //filters
    filters: function() {
      return this.$store.getters.getPENDINGRETURNSTableFilters;
    },

    table_permissions: function() {
      if (this.$store.getters.getPENDINGRETURNSTablePermissions) {
        return this.$store.getters.getPENDINGRETURNSTablePermissions.map(
          function(perm) {
            return perm.name;
          }
        );
      } else {
        return false;
      }
    },
    table_properties: function() {
      return this.$store.getters.getPENDINGRETURNSTableProperties;
    },

    itemPerPage: function() {
      return this.$store.getters.getPENDINGRETURNSTableState.per_page;
    },
    orderBy: {
      get: function() {
        return this.$store.getters.getPENDINGRETURNSTableState.sortBy;
      },
      set: function(newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getPENDINGRETURNSTableState
          );
        } else {
          this.$store.commit(SET_TABLE_SORTBY, "");
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getPENDINGRETURNSTableState
          );
        }
        this.$store.commit(SET_PAGE_LOADING, false);
      },
    },
    sortOrder: {
      get: function() {
        let sortOrder = true;

        if (
          this.$store.getters.getPENDINGRETURNSTableState.sortOrder === "asc"
        ) {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function(newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store
          .dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getPENDINGRETURNSTableState
          )
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      },
    },
    headers: function() {
      /**
       * @type {[
          {
              value: string,
              text: string,
              type: string,
              sortable: string,
              exportable: string,
              visible: string,
              mobile_visible: string,
              align: string,
              itemClass: string,
              width: string,
              class: string,
              export_order: integer,
            }
                ]}
        */
      let headers = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.$store.getters.getPENDINGRETURNSTableHeaders) {
          headers = this.$store.getters.getPENDINGRETURNSTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getPENDINGRETURNSTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        }
      } else {
        if (this.$store.getters.getPENDINGRETURNSTableHeaders) {
          headers = this.$store.getters.getPENDINGRETURNSTableHeaders.filter(
            (col) => col.visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getPENDINGRETURNSTableHeaders.filter(
            (col) => col.visible === true
          );
        }
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "aging_day")
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    exportHeaders: function() {
      try {
        return this.$store.getters.getPENDINGRETURNSTablePermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      } catch (error) {
        return null;
      }
    },
    // types: function () {
    //   return [
    //     { name: "Shipping label", value: "label" },
    //     { name: "Barcode", value: "barcode" },
    //     { name: "Invoice", value: "invoice" },
    //     { name: "Manifest", value: "manifest" },
    //     { name: "Packing summary", value: "packing_summary" },
    //     { name: "Packing slip", value: "packing_slip" },
    //   ];
    // },
    items: function() {
      let items = [];
      const serverData = this.$store.getters.getPENDINGRETURNSTableData;
      if (serverData) {
        items = serverData.data;
      }
      return items;
    },
    editItemIdData() {
      return this.editItemId;
    },
    showBulkActions: function() {
      const bulkActions = [
        "bulk_edit_destination_address",
        "assign_awb",
        "return_order_asn_create",
        "create_return_in_wms",
        "order_reset_to_assigned",
        "return_3pl_status_sync",
      ];

      if (this.table_permissions.length) {
        return this.table_permissions.some((permission) =>
          bulkActions.includes(permission)
        );
      }
      return false;
    },
  },
  methods: {
    showCancelModal() {
      if (!this.dialog3) {
        this.cancel_reason = null;
        if (this.selected.length > 0) {
          this.dialog3 = !this.dialog3;
        } else {
          Swal.fire({
            title: "Warning",
            text: `Please, select item!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        this.dialog3 = !this.dialog3;
      }
    },
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoad(true);
        this.dialog3 = false;
        const data = {
          order_ids: this.selected.map((item) => item.id),
          reason: this.cancel_reason,
        };
        ApiService.post(`/order_management/pending_audit_returns/cancel`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Cancel reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    showHoldOnModal() {
      if (!this.dialog2) {
        this.holdOn_reason = null;
        this.holdOn_date = null;
        if (this.selected.length > 0) {
          this.dialog2 = !this.dialog2;
        } else {
          Swal.fire({
            title: "Warning",
            text: `Please, select item!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        this.dialog2 = !this.dialog2;
      }
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoad(true);
        this.dialog2 = false;

        const data = {
          order_ids: this.selected.map((item) => item.id),
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(`/order_management/pending_audit_returns/hold`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `On Hold reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    async findNextOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -1;
      let nextId = null;
      this.items.forEach((element, index) => {
        if (element.id === id) {
          nextIdIndex = index + 1;
        }
      });
      // console.log("index is", nextIdIndex);
      if (parseInt(this.getItemPerPage) == nextIdIndex) {
        // console.log("last item");
        if (this.pageCount > this.getPageNumber) {
          // console.log("next page exist");
          this.setPageNumber(this.getPageNumber + 1);
          await this.$store
            .dispatch(
              UPDATE_TABLE_DATA,
              this.$store.getters.getPENDINGRETURNSTableState
            )
            .then(() => {
              this.$forceUpdate();
              // console.log("on update", this.items[0].id.id);
            });
          // console.log("next item id is", this.items[0].id.id);
          nextId = this.items[0].id;
        }
      } else if (this.items.length - 1 < nextIdIndex) {
        throw TypeError("there is no next order");
      } else {
        try {
          nextId = this.items[nextIdIndex].id;
        } catch (e) {
          // console.log(e);
          throw TypeError(e);
        }
      }
      // console.log("next id is", this.items[nextIdIndex].id.id, nextId);
      return nextId;
    },
    async findPrevOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -2;
      let nextId = null;
      this.items.forEach((element, index) => {
        if (element.id === id) {
          nextIdIndex = index - 1;
        }
      });
      // console.log("index is", nextIdIndex);
      if (nextIdIndex < 0) {
        // console.log("first item");
        if (this.getPageNumber > 1) {
          // console.log("prev page exist");
          this.setPageNumber(this.getPageNumber - 1);
          await this.$store
            .dispatch(
              UPDATE_TABLE_DATA,
              this.$store.getters.getPENDINGRETURNSTableState
            )
            .then(() => {
              this.$forceUpdate();
              // console.log("on update", this.items[0].id.id);
            });
          nextId = this.items[parseInt(this.getItemPerPage) - 1].id;
        }
      } else {
        try {
          nextId = this.items[nextIdIndex].id;
        } catch (e) {
          // console.log(e);
          throw TypeError("Can not find previous order:" + e);
        }
      }
      // console.log(nextIdIndex, nextId, this.getPageNumber, this.getItemPerPage);
      return nextId;
    },
    showNextOrder(id) {
      // console.log("current id", id);
      this.$refs.actionDialog.toggleModal();
      this.findNextOrderId(id)
        .then((data) => {
          // console.log("find next order ", data);
          if (data) {
            const item = this.items.find((item) => item.id === data);
            // console.log("item  ", item);
            this.editItem(item);
            this.updateTableContent();
          }
        })
        .catch(() => {
          // console.log(e);
          this.updateTableContent();
        });
    },
    showPrevOrder(id) {
      this.$refs.actionDialog.toggleModal();
      this.findPrevOrderId(id)
        .then((data) => {
          if (data) {
            const item = this.items.find((item) => item.id === data);
            this.editItem(item);
            this.updateTableContent();
          }
        })
        .catch(() => {
          // console.log(e);
          this.updateTableContent();
        });
    },
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getItemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getItemPerPage * this.getpageNumber - this.getItemPerPage;
          innerItems = this.items.slice(startFrom, this.getItemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    // pagination
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = { ...this.$store.getters.getPENDINGRETURNSTableState };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);

      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getPENDINGRETURNSTableState
        )
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    // filters end
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    loadDetailData(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { id: id };
      ApiService.post("/shipping/returns_management/show", data)
        .then((response) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.$refs.otherData.setData(response.data.return_management);
          this.$refs.otherData.toggleModal();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    handleDelete(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          const data = { id: item.id };
          ApiService.post(
            "/order_management/pending_audit_returns/destroy",
            data
          )
            .then(() => {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    showDetail(event, { item }) {
      this.editItem(item);
      // this.loadDetailData(item.id);
    },
    isAnyFilterSelected() {
      let isSelected = false;
      if (this.$store.getters.getPENDINGRETURNSTableState.filters.length > 0) {
        isSelected = true;
      }
      return isSelected;
    },
    // exports
    downloadItem(columns) {
      if (this.isAnyFilterSelected()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        this.$store
          .dispatch(EXPORT_TABLE_DATA, {
            columns: columns,
            filters: this.$store.getters.getPENDINGRETURNSTableState.filters,
          })
          .then(() => {
            const link = document.createElement("a");
            link.href = this.$store.getters.getPENDINGRETURNSExportUrl;
            link.download = "Export.csv";
            link.click();
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.exportSidebar.$refs.export.toggleModal();
            link.remove();
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    // exports end
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },

    async updateTableContent() {
      this.pageLoad(true);
      await this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getPENDINGRETURNSTableState
        )
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.$forceUpdate();
      this.selected = [];
      this.pageLoad(false);
    },
    editItem(id) {
      // console.log('edit item : ', id);
      this.$store.commit(SET_ACTION_TYPE, "edit");
      this.$store.commit(SET_ITEM_FOR_ACTION, id);
      this.editItemId = id;
      this.$nextTick(() => {
        this.$refs.actionDialog.toggleModal(id);
      });
    },
    // toggleCreateReturnModal(type) {
    //   this.$store.commit(SET_CREATE_RETURN_TYPE, type);
    //   this.$store.commit(SET_ACTION_TYPE, "create");
    //   this.$store.commit(SET_PAGE_LOADING, true);
    //   ApiService.post(`/order_management/pending_audit_returns/create`)
    //     .then(({ data }) => {
    //       this.$store.commit(SET_SERVER_DATA, data);
    //       this.$store.commit(SET_PAGE_LOADING, false);
    //     })
    //     .then(() => {
    //       this.$refs.actionDialog.toggleModal();
    //     });
    // },
    // assignCarrier() {
    //   if (!this.selected.length) {
    //     Swal.fire({
    //       title: "No Order Selected",
    //       text: `Select some orders to assign carrier`,
    //       icon: "warning",
    //       showConfirmButton: true,
    //     });
    //     return;
    //   }
    //   this.$refs.assignCarrier.toggleModal();
    // },
    // bulkEdit() {
    //   if (!this.selected.length) {
    //     Swal.fire({
    //       title: "No Order Selected",
    //       text: `Select some orders to assign carrier`,
    //       icon: "warning",
    //       showConfirmButton: true,
    //     });
    //     return;
    //   }
    //   this.$refs.bulkEditDestination.toggleModal();
    // },
    // bulkAssignAwb() {
    //   if (!this.selected.length) {
    //     Swal.fire({
    //       title: "No Order Selected",
    //       text: `Select some orders to assign carrier`,
    //       icon: "warning",
    //       showConfirmButton: true,
    //     });
    //     return;
    //   }
    //   this.$refs.bulkAssignAwb.toggleModal();
    // },
    // toggleCreateAsnModal() {
    //   if (!this.selected.length) {
    //     Swal.fire({
    //       title: "No Order Selected",
    //       text: `Select some orders to create ASN`,
    //       icon: "warning",
    //       showConfirmButton: true,
    //     });
    //     return;
    //   }
    //   this.$refs.createReturnAsn.toggleModal();
    // },
  },
};
</script>
<style>
.q-status-menu {
  max-height: 350px;
  overflow-y: scroll;
}
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}
.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
</style>
